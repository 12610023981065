<template>
    <div>
        <b-container class="p-4 confidentiality">
            <b-row class="p-4">
                <b-col cols="12">
                    <h2 class="text-center pb-3">
                        Confidentiality and Security Agreement
                        <hr/>
                    </h2>
                    <p>
                        I understand that Central Peninsula Hospital (CPH) in which or for whom I work, volunteer, or provide services, or
                        with whom the physician practice for which I work has a relationship (contractual or otherwise) involving the exchange
                        of health information, CPH has a legal and ethical responsibility to safeguard the privacy of all patients and to protect
                        the confidentiality of their patients' health information. Additionally, CPH must assure the confidentiality of its human
                        resources, payroll, fiscal, research, internal reporting, strategic planning, communications, computer systems and
                        management information (collectively, with patient identifiable health information, "Confidential Information").
                    </p>
                    <p>
                        In the course of my employment / assignment at CPH, I understand that I may come into possession/contact with
                        Confidential Information. I will access and use this information only as it is necessary to perform my job related duties
                        in accordance with CPH's Privacy and Security Policies, which are available on CPH's intranet (on the Security Page)
                        and the internet (under Ethics & Compliance). I further understand that I must sign and comply with this Agreement to
                        obtain authorization for access to Confidential Information.
                    </p>
                    <b-row>
                        <b-col cols="12"
                               class="px-4 py-2"
                               v-for="(term, index) in information" :key="index">
                            <b>{{ index+1 }}</b>. <span v-html="term.term"></span>
                            <ul>
                                <li v-for="(subTerm, index) in term.sub_terms" :key="index">
                                    {{ subTerm }}
                                </li>
                            </ul>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12"
                               class="px-4 py-2">
                            <b>
                                The following statements apply to physicians using CPH
                                systems containing patient identifiable health
                                information (e.g., Meditech):
                            </b>
                        </b-col>
                        <b-col cols="12"
                               class="px-4 py-2"
                               v-for="(term, index) in dr_information" :key="index">
                            <b>{{ term.number }}</b>. <span v-html="term.term"></span>
                        </b-col>
                    </b-row>
                    <hr/>
                    <b-row class="pt-3">
                        <b-col cols="12">
                            <p>
                                <b>
                                    By my signature, I acknowledge that I have read this Agreement and I agree to comply with all the terms and
                                    conditions stated above.
                                </b>
                            </p>
                            <b-form-group id="signer_name"
                                          class="required-text"
                                          label="Your Name:"
                                          label-for="signer_name_input">
                                <b-form-input id="signer_name_input"
                                              v-model="signer_name"
                                              placeholder="Printed Name"
                                              required
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <label id="signer_name__BV_label_" class="d-block required-text">Signature: <span style="color:red;">*</span></label>
                            <VueSignaturePad id="signature"
                                             width="100%"
                                             height="300px"
                                             ref="signaturePad"
                                             :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}" />
                        </b-col>
                    </b-row>
                    <b-row class="pt-3">
                        <b-col cols="12">
                            <b-button variant="danger"
                                      class="mr-2"
                                      @click="cancelTerms">
                                Cancel
                            </b-button>
                            <b-button variant="success"
                                      @click="save"
                                      :disabled=" !signer_name || $refs.signaturePad.isEmpty()">
                                Submit
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "confidentiality",
        data(){
            return{
                agreed: false,
                signer_name: "",
                information: [
                    {
                        term: "I will not in any way divulge, copy, release, sell, loan, alter, <b>or</b> destroy any Confidential Information except as explicitly authorized by CPH."
                    },
                    {
                        term: "I will not discuss Confidential Information where others can overhear the conversation. It is not acceptable to discuss Confidential Information even if the patient's name is not used."
                    },
                    {
                        term: "I will not make any unauthorized transmissions, inquiries, modifications, or purgings of Confidential Information."
                    },
                    {
                        term: "I agree that my obligations under this Agreement will continue after termination of my employment, expiration of my contract, or when my relationship ceases with CPH."
                    },
                    {
                        term: "Upon termination, I will immediately return any documents or media containing Confidential Information to CPH."
                    },
                    {
                        term: "I understand that I have no right or ownership interest in any Confidential Information accessed or created by me during my relationship with CPH."
                    },
                    {
                        term: "I will act in the best interest of CPH and in accordance with its Code of Conduct at all times during my relationship with CPH."
                    },
                    {
                        term: "I understand that violation of this Agreement may result in disciplinary action, up to and including termination of employment, suspension and loss of privileges, and/or termination of authorization to work within CPH, in accordance with CPGH's policies."
                    },
                    {
                        term: "I will only access or use systems or devices I am officially authorized to access, and will not demonstrate the operation or function of systems or devices to unauthorized individuals."
                    },
                    {
                        term: "I understand that I have no expectation of privacy when using CPH's information systems. CPH may log, access, review, and otherwise utilize information stored on or passing through its systems, including e-mail, in order to manage systems and enforce security"
                    },
                    {
                        term: "I will practice workstation security measures such as locking up portable media when not in use, using screen savers with activated passwords appropriately, and position screens away from public view."
                    },
                    {
                        term: "I will practice secure electronic communications by transmitting confidential Information only to authorized entities, in accordance with approved security standards."
                    },
                    {
                        term: "<b>I will:</b>",
                        sub_terms: [
                            "Use only my officially assigned User-ID and password (and/or token (e.g., SecurID card)).",
                            "Use only approved licensed software.",
                            "Use a device with virus protection software."
                        ]
                    },
                    {
                        term: "<b>I will never:</b>",
                        sub_terms: [
                            "Share/disclose user-IDs, passwords or tokens.",
                            "Send patient specific information via the Email system.",
                            "Use tools or techniques to break/exploit security measures.",
                            "Connect to unauthorized networks through the systems or devices."
                        ]
                    },
                    {
                        term: "<b>I will notify my manager or appropriate Information Services person if my password has been seen, disclosed, or otherwise compromised, and will report activity that violates this agreement, privacy and security policies, or any other incident that could have any adverse impact on Confidential Information.</b>"
                    }
                ],
                dr_information: [
                    {
                        number: 16,
                        term: "I will only access software systems to review patient records when I have that patient's consent to do so. By accessing a patient's record, I am affirmatively representing to CPH at the time of each access that I have the requisite patient consent to do so, and CPH may rely on that representation in granting such access for me."
                    },
                    {
                        number: 17,
                        term: "I will insure that only appropriate personnel in my office will access CPH software systems and Confidential Information and I will annually train such personnel on issues related to patient confidentiality and access."
                    },
                    {
                        number: 18,
                        term: "I will accept full responsibility for the actions of my employees who may access CPH software systems and Confidential Information."
                    }
                ]
            }
        },
        methods: {
            cancelTerms() {
                this.$refs.signaturePad.clearSignature();
                this.$emit('closeSigPad')
            },
            save() {
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                if (!isEmpty){
                    this.$emit("signatureSubmit", data, this.signer_name)
                }

            }
        }
    }
</script>

<style>
#signature {
    border: solid 2px transparent;
    border-radius: 5px;
    background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #000, #000);
    background-origin: border-box;
    background-clip: content-box, border-box;
}
.confidentiality .required-text label:after{
    content: " *";
    color: red;
}
</style>