<template>
    <section class="p-2">
        <div class="pb-2">
            <div>
                <div class="container pt-3">
                    <div class="row">
                        <div class="col-sm-11">
                            <h1>CPH - PACS Request</h1>
                            <hr>
                            <br>
                            <agentInfo @setForm="formSubmit"
                                       :form-reset="reset_form"
                            ></agentInfo>

                        </div>
                    </div>
                </div>
            </div>

        </div>
        <b-modal v-model="terms_and_conditions"
                 size="xl"
                 scrollable
                 hide-header
                 hide-footer>
            <confidentiality @signatureSubmit="setSignature"
                             @closeSigPad="terms_and_conditions = false"/>

        </b-modal>
        <b-modal v-model="form_complete"
                 size="lg"
                 hide-header
                 hide-footer >
            <div class="p-4">
                <b-row class="text-center">
                    <b-col cols="12">
                        <div v-if="submit_error">
                            <h2 class="text-danger py-2">
                                It seems there was an error with your submission
                            </h2>
                            <h4 class="py-2">
                                Please contact:
                            </h4>
                            <p class="h5 mb-2">
                                <b-icon icon="telephone"></b-icon>
                                <a href='tel:907-714-4704'>
                                    (907)714-4704
                                </a>
                            </p>
                            <p class="h5 mb-2">
                                <b-icon icon="envelope"></b-icon>
                                <a :href='"mailto:applicationinfo@cpgh.org?subject=PACS Request "+error_msg'>
                                    applicationinfo@cpgh.org
                                </a>
                            </p>
                        </div>
                        <div v-else>
                            <h2 class="text-success py-2">
                                PACS Request has successfully been submitted
                            </h2>
                            <h4 class="py-2">
                                For any additional questions please contact:
                            </h4>
                            <p class="h5 mb-2">
                                <b-icon icon="telephone"></b-icon>
                                <a href='tel:907-714-4704'>
                                    (907)714-4704
                                </a>
                            </p>
                            <p class="h5 mb-2">
                                <b-icon icon="envelope"></b-icon>
                                <a :href='"mailto:applicationinfo@cpgh.org?subject=PACS Request Questions"'>
                                    applicationinfo@cpgh.org
                                </a>
                            </p>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-modal>
        <b-modal v-model="loading"
                 id="loading-modal"
                 size="lg"
                 centered
                 hide-header no-close-on-backdrop
                 hide-footer >
            <div class="text-center">
                <b-spinner variant="info" style="width: 12rem; height: 12rem;" type="grow"
                           label="Spinning" >

                </b-spinner>
            </div>
        </b-modal>


    </section>
</template>

<script>
    import config from '../../public/config.json'
    import agentInfo from "../components/agent-info"
    import confidentiality from "../components/confidentiality"

    import axios from "axios";
    axios.defaults.headers.common['Content-Type'] = 'application/json'

    export default {
        name: "Home",
        components: {
            agentInfo,
            confidentiality
        },

        data(){
            return{
                loading: false,
                signature: false,
                submit_error: false,
                form_complete: false,
                terms_and_conditions: false,
                completed: false,
                currentPage: 1,
                form: {},
                sig_one: "",
                reset_form: false,
                error_msg: ""
            }
        },
        methods: {
            formSubmit: function (value){
                // Sets form var
                this.submit_error = false
                this.form = value
                this.terms_and_conditions = true
            },
            setSignature: function (value, value_two){
                // Sets first sig
                this.terms_and_conditions = false
                this.loading = true
                this.form.signature = value
                this.form.signer_name = value_two
                window.scrollTo(0,0);
                // this.terms_and_conditions = false

                this.POST(this.form)
            },
            POST: function(params){
                var route = config.route;

                return new Promise((resolve, reject) => {
                    axios.post(route, params)
                        .then(response => {
                            this.reset_form = true
                            this.form_complete = true
                            this.loading = false
                            this.resetValues()
                            window.scrollTo(0,0);
                            resolve(response.data);
                        })
                        .catch(err => {
                            this.submit_error = true
                            this.form_complete = true
                            this.loading = false
                            reject(err);
                        });
                });
            },
            resetValues: function (){
                var that = this;
                setTimeout(function() {
                    that.reset_form = false
                    that.form = {}
                    that.currentPage = 1
                }, 500);

            }
        }
    }
</script>

<style >
#loading-modal .modal-content{
    background-color: transparent!important;
    border: none;
}
</style>
