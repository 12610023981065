<template>
    <div>
        <b-form class="pacs-form">
            <b-form-row>
                <b-col cols="12" md="5">
                    <b-form-group id="first_name"
                                  class="required-text"
                                  label="Your First Name:"
                                  label-for="first_name_input">
                        <b-form-input id="first_name_input"
                                      v-model="form.first_name"
                                      placeholder="Enter first name"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="5">
                    <b-form-group id="last_name"
                                  class="required-text"
                                  label="Your Last Name:"
                                  label-for="last_name_input">
                        <b-form-input id="last_name_input"
                                      v-model="form.last_name"
                                      placeholder="Enter last name"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="2">
                    <b-form-group id="last_four_ss"
                                  class="required-text"
                                  label="SS#(Last 4 only):"
                                  label-for="last_four_ss_input">
                        <b-form-input id="last_four_ss_input"
                                      :maxlength="4"
                                      :state="isSocialStateValid"
                                      type="text"
                                      pattern="\d*"
                                      v-model="form.last_four_ss"
                                      placeholder="Enter SS#"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group id="email"
                                  class="required-text"
                                  label="Email:"
                                  label-for="email_input">
                        <b-form-input
                            :class="{error: !valid_email}"
                            id="email_input"
                            v-model="form.email"
                            type="email"
                            placeholder="Enter email"
                            required
                        ></b-form-input>
                        <div class="text-danger" v-if="!valid_email">
                            <small>Email not valid</small>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group id="phone_number"
                                  class="required-text"
                                  label="Phone Number:"
                                  label-for="phone_number_input">
                        <b-form-input
                            id="phone_number_input"
                            v-model="form.phone_number"
                            placeholder="Enter phone number"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group id="fax"
                                  label="Fax Number:"
                                  label-for="fax_input">
                        <b-form-input
                            id="fax_input"
                            v-model="form.fax"
                            placeholder="Enter fax number"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group id="agency"
                                  class="required-text"
                                  label="Agency:"
                                  label-for="agency_input">
                        <b-form-input id="agency_input"
                                      v-model="form.agency"
                                      placeholder="Enter agency"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group id="address"
                                  label="Address:"
                                  class="required-text"
                                  label-for="address_input">
                        <b-form-input id="address_input"
                                      v-model="form.address"
                                      placeholder="Enter Address"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group id="address_2"
                                  label="Address Line 2:"
                                  label-for="address2_input">
                        <b-form-input id="address2_input"
                                      v-model="form.address_2"
                                      placeholder="Enter Address"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group id="city"
                                  class="required-text"
                                  label="City:"
                                  label-for="city_input">
                        <b-form-input id="city_input"
                                      v-model="form.city"
                                      placeholder="Enter City"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group id="state"
                                  class="required-text"
                                  label="State:"
                                  label-for="state_input">
                        <b-form-select v-model="form.state" :options="options"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group id="zip"
                                  class="required-text"
                                  label="Zip:"
                                  label-for="zip_input">
                        <b-form-input id="zip_input"
                                      v-model="form.zip"
                                      placeholder="Enter Zip"
                                      required
                        ></b-form-input>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <b-form-row class="pt-5">
                <b-button @click="validateForm(form)"
                          :disabled="disabled"
                          variant="success">
                    Submit
                </b-button>
            </b-form-row>
        </b-form>
    </div>
</template>

<script>
    export default {
        name: "agent-info",
        props: {
            formReset: {
                type: Boolean
            }
        },
        data(){
            return{
                disabled: true,
                valid_email: true,
                form: {
                    first_name: "",
                    last_name: "",
                    agency: "",
                    address: "",
                    address_2: "",
                    city: "",
                    state: "",
                    zip: "",
                    last_four_ss: "",
                    email: "",
                    phone_number: "",
                    fax: "",
                    signature: ""
                },
                options: [
                    {
                        value: "",
                        text: 'Please select an option'
                    },
                    {
                        text: "Alabama",
                        value: "AL"
                    },
                    {
                        text: "Alaska",
                        value: "AK"
                    },
                    {
                        text: "American Samoa",
                        value: "AS"
                    },
                    {
                        text: "Arizona",
                        value: "AZ"
                    },
                    {
                        text: "Arkansas",
                        value: "AR"
                    },
                    {
                        text: "California",
                        value: "CA"
                    },
                    {
                        text: "Colorado",
                        value: "CO"
                    },
                    {
                        text: "Connecticut",
                        value: "CT"
                    },
                    {
                        text: "Delaware",
                        value: "DE"
                    },
                    {
                        text: "District of Columbia",
                        value: "DC"
                    },
                    {
                        text: "Micronesia",
                        value: "FM"
                    },
                    {
                        text: "Florida",
                        value: "FL"
                    },
                    {
                        text: "Georgia",
                        value: "GA"
                    },
                    {
                        text: "Guam",
                        value: "GU"
                    },
                    {
                        text: "Hawaii",
                        value: "HI"
                    },
                    {
                        text: "Idaho",
                        value: "ID"
                    },
                    {
                        text: "Illinois",
                        value: "IL"
                    },
                    {
                        text: "Indiana",
                        value: "IN"
                    },
                    {
                        text: "Iowa",
                        value: "IA"
                    },
                    {
                        text: "Kansas",
                        value: "KS"
                    },
                    {
                        text: "Kentucky",
                        value: "KY"
                    },
                    {
                        text: "Louisiana",
                        value: "LA"
                    },
                    {
                        text: "Maine",
                        value: "ME"
                    },
                    {
                        text: "Marshall Islands",
                        value: "MH"
                    },
                    {
                        text: "Maryland",
                        value: "MD"
                    },
                    {
                        text: "Massachusetts",
                        value: "MA"
                    },
                    {
                        text: "Michigan",
                        value: "MI"
                    },
                    {
                        text: "Minnesota",
                        value: "MN"
                    },
                    {
                        text: "Mississippi",
                        value: "MS"
                    },
                    {
                        text: "Missouri",
                        value: "MO"
                    },
                    {
                        text: "Montana",
                        value: "MT"
                    },
                    {
                        text: "Nebraska",
                        value: "NE"
                    },
                    {
                        text: "Nevada",
                        value: "NV"
                    },
                    {
                        text: "New Hampshire",
                        value: "NH"
                    },
                    {
                        text: "New Jersey",
                        value: "NJ"
                    },
                    {
                        text: "New Mexico",
                        value: "NM"
                    },
                    {
                        text: "New York",
                        value: "NY"
                    },
                    {
                        text: "North Carolina",
                        value: "NC"
                    },
                    {
                        text: "North Dakota",
                        value: "ND"
                    },
                    {
                        text: "Northern Mariana Islands",
                        value: "MP"
                    },
                    {
                        text: "Ohio",
                        value: "OH"
                    },
                    {
                        text: "Oklahoma",
                        value: "OK"
                    },
                    {
                        text: "Oregon",
                        value: "OR"
                    },
                    {
                        text: "Palau",
                        value: "PW"
                    },
                    {
                        text: "Pennsylvania",
                        value: "PA"
                    },
                    {
                        text: "Puerto Rico",
                        value: "PR"
                    },
                    {
                        text: "Rhode Island",
                        value: "RI"
                    },
                    {
                        text: "South Carolina",
                        value: "SC"
                    },
                    {
                        text: "South Dakota",
                        value: "SD"
                    },
                    {
                        text: "Tennessee",
                        value: "TN"
                    },
                    {
                        text: "Texas",
                        value: "TX"
                    },
                    {
                        text: "Utah",
                        value: "UT"
                    },
                    {
                        text: "Vermont",
                        value: "VT"
                    },
                    {
                        text: "Virgin Islands",
                        value: "VI"
                    },
                    {
                        text: "Virginia",
                        value: "VA"
                    },
                    {
                        text: "Washington",
                        value: "WA"
                    },
                    {
                        text: "West Virginia",
                        value: "WV"
                    },
                    {
                        text: "Wisconsin",
                        value: "WI"
                    },
                    {
                        text: "Wyoming",
                        value: "WY"
                    },
                    {
                        text: "International",
                        value: "International"
                    }
                ],
                reset_form: false
            }
        },
        computed: {
            isSocialStateValid() {
                if (this.form.last_four_ss) {
                    return this.validateSS(this.form.last_four_ss);
                }
                return null;
            }
        },
        methods: {
            validateForm: function (form){
                // Validates form
                this.$emit("setForm", this.form)
                if (this.validateEmail(form.email)){
                    // Email is valid, opens sig modal
                    // Email is valid, opens sig modal
                    this.$emit("setForm", this.form)
                }
                else{
                    // Email isnt valid
                    this.valid_email = false
                }
            },
            validateEmail: function (email){
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            validateSS: function (social){

                return social.length == 4 ? true : false;
            }
        },
        watch: {
            form: {
                handler (newVal){
                    if (
                        newVal.first_name === "" ||
                        newVal.last_name === "" ||
                        newVal.agency === "" ||
                        newVal.address === "" ||
                        newVal.city === "" ||
                        newVal.state === "" ||
                        newVal.zip === "" ||
                        newVal.last_four_ss === "" ||
                        !this.isSocialStateValid ||
                        newVal.email === "" ||
                        newVal.phone_number === ""
                    ){
                        this.disabled = true
                    }
                    else{
                        this.disabled = false
                    }
                },
                deep: true
            },
            formReset: function (newVal){
                if (newVal){
                    this.form = {
                        first_name: "",
                        last_name: "",
                        agency: "",
                        address: "",
                        address_2: "",
                        city: "",
                        state: "",
                        zip: "",
                        last_four_ss: "",
                        email: "",
                        phone_number: "",
                        fax: "",
                        signature: ""
                    }
                }
            }
        }
    }
</script>

<style>
.form-control.error{
    border: 1px solid red;
}
.pacs-form .required-text label:after{
    content: " *";
    color: red;
}
</style>